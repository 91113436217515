import getConfig from 'next/config';

const useMigrationIfNeeded = (): { migrateIfNeeded: () => void } => {
    const migrateIfNeeded = () => {
        const { version } = window.localStorage;
        const { publicRuntimeConfig } = getConfig();
        const { version: currentVersion } = publicRuntimeConfig;

        if (version !== currentVersion) {
            // add migration logic here
            localStorage.setItem('version', currentVersion);
        }
    };

    return { migrateIfNeeded };
};

export default useMigrationIfNeeded;
