import {
    BannerNotificationWithId,
    BannerType,
    Languages,
    LocalizedNotification,
} from '@livv/models';
import { addDays, getTime } from 'date-fns';
import { CONTACT_EMAIL } from './consts/livv';
import BaseStorage from './localStorage/BaseStorage';
import { UserPrivileges } from '@models/users';
import {
    NOTIFICATIONS_STORAGE_KEY,
    NOTIFY_FOR_EXPIRE_TRIAL_COUNT_DAY,
} from '@utils/consts/bannerNotifications';
import { getFormattedDurationInDayFromInterval } from '@utils/date';

const notificationStorage = new BaseStorage<Date>(NOTIFICATIONS_STORAGE_KEY, {
    format: JSON.stringify,
    parse: JSON.parse,
});

const getLocalBannerNotification = (
    type: BannerType,
    trialEndDate: Date,
): BannerNotificationWithId | undefined => {
    const today = new Date();

    switch (type) {
        case 'endTrial':
            return {
                id: type,
                localizedContent: Object.values(Languages).reduce(
                    (acc, lang) => ({
                        ...acc,
                        [lang]: {
                            actionLink: {
                                href: `mailto:${CONTACT_EMAIL}`,
                                label: `notifications:banner.${type}.callToActionLabel`,
                            },
                            main: `notifications:banner.${type}.emphasizedContent`,
                            secondary: `notifications:banner.${type}.content`,
                            secondaryTOptions: {
                                remainingTime: getFormattedDurationInDayFromInterval(
                                    {
                                        end: trialEndDate,
                                        start: today,
                                    },
                                    lang,
                                    true,
                                ),
                            },
                        } as LocalizedNotification,
                    }),
                    {} as BannerNotificationWithId['localizedContent'],
                ),
                priority: 'medium',
            };

        default:
            return undefined;
    }
};

const getLocalBannerNotifications = (
    userPrivileges?: UserPrivileges,
): BannerNotificationWithId[] => {
    const localBannerNotifications: BannerNotificationWithId[] = [];

    if (!userPrivileges) return localBannerNotifications;

    const { isPaidCustomer, trialEndDate } = userPrivileges;
    const now = Date.now();

    if (isPaidCustomer || !trialEndDate) return localBannerNotifications;

    // Trial end date notification
    if (!notificationStorage.getItem('endTrial')) {
        const isInTheFutur = trialEndDate >= now;
        const isCloseEnough =
            trialEndDate <= getTime(addDays(now, NOTIFY_FOR_EXPIRE_TRIAL_COUNT_DAY));
        if (isInTheFutur && isCloseEnough) {
            const endTrialNotification = getLocalBannerNotification(
                'endTrial',
                new Date(trialEndDate),
            );
            if (endTrialNotification) {
                localBannerNotifications.push(endTrialNotification);
            }
        }
    }

    return localBannerNotifications;
};

export default getLocalBannerNotifications;
