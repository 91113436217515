import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import BaseStorage from '@utils/localStorage/BaseStorage';

export type PathKey = 'prevPath' | 'currentPath';

const usePageTracking = () => {
    const router = useRouter();
    const storage = useMemo(
        () => new BaseStorage<string, PathKey>('PAGE_TRACKING', undefined, 'sessionStorage'),
        [],
    );

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const handleRouteChange = () => {
            const prevPath = storage.getItem('currentPath');
            storage.setItem('prevPath', prevPath ?? '');
            storage.setItem('currentPath', window.location.pathname);
        };

        // Store the initial path on the first render
        handleRouteChange();

        // Listen to route changes
        router.events.on('routeChangeComplete', handleRouteChange);

        // Cleanup the event listener on component unmount
        // eslint-disable-next-line consistent-return
        return () => router.events.off('routeChangeComplete', handleRouteChange);
    }, [router.events, storage]);
};

export default usePageTracking;
