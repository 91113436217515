import { CollectionName, StatisticWithId, UserPrivileges } from '@livv/models';
import { collection } from 'firebase/firestore';
import { fireStoreCustomIdConverter, firestoreIdConverter } from './firestoreCollection';
import { db } from '@config/firebase/client';

export const analysesCollection = collection(db, CollectionName.ANALYSES).withConverter(
    firestoreIdConverter,
);

export const articlesCollection = collection(db, CollectionName.ARTICLES).withConverter(
    firestoreIdConverter,
);

export const bannerNotificationsCollection = collection(
    db,
    CollectionName.BANNER_NOTIFICATIONS,
).withConverter(firestoreIdConverter);

export const citationsCollection = collection(db, CollectionName.CITATIONS).withConverter(
    firestoreIdConverter,
);

export const codeArticlesCollection = collection(db, CollectionName.CODE_ARTICLES).withConverter(
    firestoreIdConverter,
);

export const decisionsCollection = collection(db, CollectionName.DECISIONS).withConverter(
    firestoreIdConverter,
);

export const dynamicFlagsCollection = collection(db, CollectionName.DYNAMIC_FLAGS).withConverter(
    firestoreIdConverter,
);

export const emailRecipientsCollection = collection(
    db,
    CollectionName.EMAIL_RECIPIENTS,
).withConverter(firestoreIdConverter);

export const glossaryTermsCollection = collection(db, CollectionName.GLOSSARY_TERMS).withConverter(
    firestoreIdConverter,
);

export const keywordNodesCollection = collection(db, CollectionName.KEYWORD_NODES).withConverter(
    firestoreIdConverter,
);

export const lawsCollection = collection(db, CollectionName.LAW_TEXTS).withConverter(
    firestoreIdConverter,
);

export const publicationSheetsCollection = collection(
    db,
    CollectionName.PUBLICATION_SHEETS,
).withConverter(firestoreIdConverter);

export const schemasCollection = collection(db, CollectionName.SCHEMAS).withConverter(
    firestoreIdConverter,
);

export const statisticEntriesCollection = collection(
    db,
    CollectionName.STATISTIC_ENTRIES,
).withConverter(firestoreIdConverter);

export const statisticsCollection = collection(
    db,
    CollectionName.STATISTICS,
).withConverter<StatisticWithId>(fireStoreCustomIdConverter<StatisticWithId>('statisticId'));

export const treeNodesCollection = collection(db, CollectionName.TREE_NODES).withConverter(
    firestoreIdConverter,
);

export const treeCollection = collection(db, CollectionName.TREES).withConverter(
    firestoreIdConverter,
);

export const usageStatsCollection = collection(db, CollectionName.USAGE_STATS).withConverter(
    firestoreIdConverter,
);

export const userCollection = collection(db, CollectionName.USERS);

export const userPrivilegesCollection = collection(
    db,
    CollectionName.USER_PRIVILEGES,
).withConverter<UserPrivileges>(firestoreIdConverter);

export const usersCollection = collection(db, CollectionName.USERS).withConverter(
    firestoreIdConverter,
);

export const publicAccessCollection = collection(db, CollectionName.PUBLIC_ACCESS).withConverter(
    firestoreIdConverter,
);
