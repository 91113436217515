import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC } from 'react';

const NavBar = dynamic(() => import('@components/NavBar'), {
    ssr: true,
});

const MinimalNavBar = dynamic(() => import('@components/NavBar/MinimalNavBar'), {
    ssr: true,
});

interface DynamicNavBarProps {
    clientOs?: string;
}

const DynamicNavBar: FC<DynamicNavBarProps> = ({ clientOs }) => {
    const router = useRouter();
    const isPaymentRoute = router.pathname.startsWith('/payment');

    if (isPaymentRoute) {
        return <MinimalNavBar />;
    }

    return <NavBar clientOs={clientOs} />;
};

export default DynamicNavBar;
