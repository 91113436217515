import Button, { ButtonProps } from '@mui/material/Button';
import Link from 'next/link';
import React from 'react';
import { LinkProps } from '@utils/types/link';

interface ButtonLinkSpecificProps {
    openInNewTab?: boolean;
}

interface ButtonLinkProps extends LinkProps<ButtonLinkSpecificProps & ButtonProps> {}

// following good practice from https://nextjs.org/docs/api-reference/next/link for SEO purpose
const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonLinkProps>(
    ({ href, prefetch, children, openInNewTab, ...props }, ref) => (
        <Link href={href} prefetch={prefetch} target={openInNewTab ? '_blank' : undefined}>
            <Button ref={ref} {...props}>
                {children}
            </Button>
        </Link>
    ),
);

export default ButtonLink;
