import { FC, ReactNode, createContext, useContext, useMemo } from 'react';
import { useAlertSnackBar } from '@utils/hooks';

export interface SnackbarContextProps {
    setSuccessMessage: (message: string) => void;
}

const SnackbarContext = createContext<SnackbarContextProps | null>(null);

export const useSnackbarContext = (): SnackbarContextProps => {
    const value = useContext(SnackbarContext);
    if (!value) throw new Error('Snackbar context needed');

    return value;
};

const SnackbarContextProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    const [successSnackBar, setSuccessMessage] = useAlertSnackBar({
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        severity: 'success',
    });

    const contextValue = useMemo<SnackbarContextProps>(
        () => ({ setSuccessMessage }),
        [setSuccessMessage],
    );

    return (
        <SnackbarContext.Provider value={contextValue}>
            {children}
            {successSnackBar}
        </SnackbarContext.Provider>
    );
};

export default SnackbarContextProvider;
