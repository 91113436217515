type StorageType = 'localStorage' | 'sessionStorage';

/**
 * Partial implementation of {@link Storage}
 */
export default class BaseStorage<T = string, ItemKey = string> {
    /**
     * The key to put before any item of this storage collection
     * @private
     */
    protected readonly globalKey: string;

    protected readonly storageType: StorageType;

    protected readonly parse!: (rawDataString: string) => T;

    protected readonly format!: (item: T) => string;

    constructor(
        globalKey: string,
        options?: {
            format: (item: T) => string;
            parse: (rawDataString: string) => T;
        },
        storageType: StorageType = 'localStorage',
    ) {
        this.globalKey = globalKey;
        this.storageType = storageType;
        if (options) {
            this.format = options.format;
            this.parse = options.parse;
        }
    }

    formatKey(itemKey: ItemKey): string {
        return `${this.globalKey}.${itemKey}`;
    }

    getItem(itemKey: ItemKey): T | undefined {
        const { storage } = this;

        if (!storage) return undefined;

        const rawItemString = storage.getItem(this.formatKey(itemKey));

        if (!rawItemString) return undefined;

        return this.parse ? this.parse(rawItemString) : (rawItemString as unknown as T);
    }

    setItem(itemKey: ItemKey, item: T): void {
        const { storage } = this;

        if (!storage) return;

        const rawItemString = this.format ? this.format(item) : (item as unknown as string);

        storage.setItem(this.formatKey(itemKey), rawItemString);
    }

    exists(itemKey: ItemKey): boolean {
        const { storage } = this;

        if (!storage) return false;

        return storage.getItem(this.formatKey(itemKey)) !== null;
    }

    removeItem(itemKey: ItemKey): void {
        if (this.storage) {
            this.storage.removeItem(this.formatKey(itemKey));
        }
    }

    private get storage(): Storage | null {
        if (typeof window === 'undefined') {
            return null;
        }

        return this.storageType === 'localStorage' ? window.localStorage : window.sessionStorage;
    }
}
