import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    WithFieldValue,
} from 'firebase/firestore';

export const fireStoreCustomIdConverter = <T>(idField = 'id'): FirestoreDataConverter<T> => ({
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>): T => {
        const objectWithId = {
            ...snapshot.data(),
            [idField]: snapshot.id,
        };

        return objectWithId as T;
    },
    toFirestore: (obj: T): WithFieldValue<DocumentData> => {
        return obj as WithFieldValue<DocumentData>;
    },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const firestoreIdConverter = fireStoreCustomIdConverter<any>();
