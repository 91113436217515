import { CollectionName } from '@livv/models';
import { ENVIRONMENT } from '@livv/utils/helpers';
import { collection, query, where } from 'firebase/firestore';
import { FC, ReactElement } from 'react';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { db } from '@config/firebase/client';
import { slack } from '@functions';
import Unavailable from '@screens/Unavailable';

interface DatabaseCheckerProps {
    children: ReactElement;
    databaseAccessible?: boolean | null;
}

const DatabaseChecker: FC<DatabaseCheckerProps> = ({ children, databaseAccessible }) => {
    if (!databaseAccessible) {
        slack.sendNotification({
            message: `${ENVIRONMENT}: 🔴 Firebase down! Can't request database`,
        });

        return <Unavailable />;
    }

    return children;
};

const ClientSideCheck: FC<Omit<DatabaseCheckerProps, 'databaseAccessible'>> = ({ children }) => {
    const pingCollection = collection(db, CollectionName.PING);
    const [pings, loading, pingError] = useCollectionDataOnce(
        query(pingCollection, where('exists', '==', true)),
    );
    // you can have the request denied if user is not logged in yet (login page)
    // but this does not mean firestore is down
    const firestoreCrashed = pingError && pingError.code !== 'permission-denied';
    const error = !loading && (firestoreCrashed || !pings);

    return <DatabaseChecker databaseAccessible={!error}>{children}</DatabaseChecker>;
};

// Be careful when changing this component, as it will have a major impact on the SSR
const DatabaseCheckerWrapped: FC<DatabaseCheckerProps> = ({ children, databaseAccessible }) =>
    databaseAccessible === undefined ? (
        <ClientSideCheck>{children}</ClientSideCheck>
    ) : (
        <DatabaseChecker databaseAccessible={databaseAccessible}>{children}</DatabaseChecker>
    );

export default DatabaseCheckerWrapped;
