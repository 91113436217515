import { DocumentData, DocumentSnapshot } from '@firebase/firestore-types';
import { CollectionName } from '@livv/models';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@config/firebase/client';

const getData = async <T extends DocumentData>(
    docId: string,
    collectionName: CollectionName,
    options?: {
        withId?: boolean;
    },
): Promise<T | undefined> => {
    const document = doc(db, collectionName, docId);
    const snapshot = (await getDoc(document)) as unknown as DocumentSnapshot<T>;
    const data = snapshot.data();

    if (options?.withId) {
        return data && { ...data, id: snapshot.id };
    }

    return data;
};

export default getData;
