import { CollectionName } from '@livv/models';
import { isAllowedToConsumePaidContent } from '@livv/utils/helpers';
import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { UserPrivileges, UserWithId } from '@models/users';
import getData from '@utils/clientSide/getData';
import { useAuth } from '@utils/context/auth';
import { useAmplitude } from '@utils/hooks';
import { processUserProperties } from '@utils/types/amplitude/userProperties';

export type FullUserPrivileges = UserPrivileges & {
    isAllowedToConsumePaidContent: boolean;
    isEmailVerified: boolean;
};

export interface UserContextValue {
    info?: UserWithId;
    loading: boolean;
    privileges?: FullUserPrivileges;
    setUser: (user: UserWithId) => void;
    setUserPrivileges: (userPrivileges: UserPrivileges) => void;
}

export const UserContext = createContext<UserContextValue>({
    loading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUser: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUserPrivileges: () => {},
});

const useUser = (): UserContextValue => useContext(UserContext);

interface UserContextProviderProps {
    children: ReactNode;
    emailVerified?: boolean | null;
    isAuthentified: boolean;
    loading: boolean;
    user?: UserWithId | null;
    userPrivileges?: UserPrivileges | null;
}

type ProvidedUser = UserWithId | undefined | null;

const UserContextProvider: FC<UserContextProviderProps> = ({
    children,
    emailVerified,
    isAuthentified,
    loading,
    user: providedUser,
    userPrivileges: providedUserPrivileges,
}) => {
    const [user, setUser] = useState<UserWithId | undefined | null>(providedUser as ProvidedUser);
    const [userPrivileges, setUserPrivileges] = useState<UserPrivileges | undefined | null>(
        providedUserPrivileges,
    );
    // set amplitude properties
    const { setUserProperties } = useAmplitude();
    if (userPrivileges && user) {
        setUserProperties(user.id, processUserProperties(user, userPrivileges));
    }

    useEffect(() => {
        setUser(providedUser as ProvidedUser);
        setUserPrivileges(providedUserPrivileges);
    }, [providedUser, providedUserPrivileges]);

    const isEmailVerified = Boolean(userPrivileges?.skipEmailVerification || emailVerified);
    const contextValue: UserContextValue = useMemo(
        () => ({
            info: isAuthentified && user ? user : undefined,
            loading,
            privileges:
                isAuthentified && user && userPrivileges
                    ? {
                          ...userPrivileges,
                          isAllowedToConsumePaidContent: isAllowedToConsumePaidContent(
                              userPrivileges,
                              isEmailVerified,
                          ),
                          isEmailVerified,
                      }
                    : undefined,
            setUser,
            setUserPrivileges,
        }),
        [isAuthentified, isEmailVerified, loading, user, userPrivileges],
    );

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

interface ClientUserContextProviderProps {
    authLoading: boolean;
    children: ReactNode;
    emailVerified?: boolean | null;
    userId: string;
}

const ClientUserContextProvider: FC<ClientUserContextProviderProps> = ({
    authLoading,
    children,
    emailVerified,
    userId,
}) => {
    const [userLoading, setUserLoading] = useState(Boolean(userId));
    const [error, setError] = useState<boolean>();
    const [user, setUser] = useState<UserWithId>();
    const [userPrivileges, setUserPrivileges] = useState<UserPrivileges>();

    const fetchUser = async (uid: string) => {
        try {
            setUserLoading(true);
            const [fetchedUser, fetchedUserPrivileges] = await Promise.all([
                getData<UserWithId>(uid, CollectionName.USERS, { withId: true }),
                getData<UserPrivileges>(uid, CollectionName.USER_PRIVILEGES),
            ]);
            setError(undefined);
            setUser(fetchedUser);
            setUserPrivileges(fetchedUserPrivileges);
        } catch (fetchUserError) {
            setError(Boolean(fetchUserError));
        } finally {
            setUserLoading(false);
        }
    };

    useEffect(() => {
        if (userId) {
            fetchUser(userId);
        }
    }, [userId]);

    useErrorHandler(error);

    const isLoading = useMemo(
        () =>
            Boolean(
                authLoading ||
                    userLoading ||
                    (userId && (!user || !userPrivileges || Boolean(error))),
            ),
        [authLoading, userLoading, userId, user, userPrivileges, error],
    );

    return (
        <UserContextProvider
            emailVerified={emailVerified}
            isAuthentified={Boolean(userId)}
            loading={isLoading}
            user={user}
            userPrivileges={userPrivileges}
        >
            {children}
        </UserContextProvider>
    );
};

interface UserContextProviderWrappedProps {
    children: ReactNode;
    user?: UserWithId | null;
    userPrivileges?: UserPrivileges | null;
}

/** Covered use cases:
 *- When the user is authenticated (`auth` is defined), but the user information (`user`)
 *  and privileges (`userPrivileges`) are not yet available, we need to fetch this data.
 *- This can happen if authentication has been validated but fetching user details is still in progress.
 *  To avoid inconsistent states in the application, we need to force fetching the missing data.
 *- The `useEffect` is executed only when the user is authenticated but other information is missing,
 *  which helps limit the impact on other users (non-authenticated).
 *- The `useEffect` should not penalize authenticated users in terms of layout shift, as the state of
 *   `skipClientFetching` is handled quickly, and components only rely on the availability of essential information.
 */
const UserContextProviderWrapped: FC<UserContextProviderWrappedProps> = ({
    children,
    user,
    userPrivileges,
}) => {
    const { user: auth, isLoading: authLoading } = useAuth();

    /** The `skipClientFetching` variable is used to determine whether we should
     * avoid triggering a new fetch of user information from the client.
     * It is initialized based on the current information: if the user (`auth`),
     * the user information (`user`), and the user privileges (`userPrivileges`) are available.
     * The goal is to avoid unnecessary API calls if we already have the required information.
     */
    const [skipClientFetching, setSkipClientFetching] = useState(
        auth !== undefined && user !== undefined && userPrivileges !== undefined,
    );

    /** This `useEffect` checks if the user is authenticated (`auth` is defined), but the user information
     * (`user`) and privileges (`userPrivileges`) have not yet been retrieved.
     * In this case, we need to set `skipClientFetching` to `false` to indicate that we need to fetch this
     * information on the client side. This resolves the issue where `auth` is available but the other user
     * information is not, likely due to a delay in fetching the data. The `useEffect` ensures that we fetch
     * this data to complete the user's state.
     */

    useEffect(() => {
        if (auth && user === undefined && userPrivileges === undefined) {
            setSkipClientFetching(false);
        }
    }, [auth, authLoading, user, userPrivileges]);

    /** If `skipClientFetching` is true, it means we have all the necessary information for the user
     * (`auth`, `user`, `userPrivileges`). Therefore, we use `UserContextProvider` to provide this data directly.
     * This helps avoid unnecessary API calls and improves performance.
     * If `skipClientFetching` is false, it means we still need to fetch some information (such as user privileges).
     * In this case, we use `ClientUserContextProvider` to handle data fetching on the client side.
     */
    return skipClientFetching ? (
        <UserContextProvider
            emailVerified={auth?.emailVerified}
            isAuthentified={Boolean(auth)}
            loading={false}
            user={user}
            userPrivileges={userPrivileges}
        >
            {children}
        </UserContextProvider>
    ) : (
        <ClientUserContextProvider
            authLoading={authLoading}
            emailVerified={auth?.emailVerified}
            userId={auth?.uid ?? ''}
        >
            {children}
        </ClientUserContextProvider>
    );
};

export { UserContextProviderWrapped as UserContextProvider };

export default useUser;
