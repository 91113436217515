import { Grid, Stack, Typography, Container, SxProps, Theme, Link } from '@mui/material';
import { FC } from 'react';
import { sxs } from '@utils/mui/sx';

const titleSx: SxProps = {
    color: 'common.white',
};

const titleContainerSx: SxProps<Theme> = {
    '&.MuiContainer-root': {
        backgroundColor: 'grey.100',
        maxWidth: 900,
        mb: 8,
        mt: 5,
        mx: 'auto',
        textAlign: 'center',
    },
};

const subtitleLinkSx: SxProps = {
    color: 'inherit',
    textDecoration: 'underline',
};

interface HeaderBarProps {
    sx?: SxProps;
    subtitle?: string;
    subtitleHref?: string;
    title?: string | JSX.Element;
    titleContainerSx?: SxProps;
}

const HeaderBar: FC<HeaderBarProps> = ({
    sx,
    subtitle,
    subtitleHref,
    title,
    titleContainerSx: titleContainerSxOverride = {},
}) => {
    const subtitleAsLink = Boolean(subtitleHref);
    const titleElement: JSX.Element = (
        <Stack spacing={2}>
            {typeof title === 'string' ? (
                <Typography sx={titleSx} variant="h1">
                    {title}
                </Typography>
            ) : (
                title
            )}
            {subtitle && (
                <Typography sx={titleSx} variant="body1">
                    {!subtitleAsLink && subtitle}
                    {subtitleAsLink && (
                        <Link href={subtitleHref} sx={subtitleLinkSx}>
                            {subtitle}
                        </Link>
                    )}
                </Typography>
            )}
        </Stack>
    );

    return (
        <Grid
            data-test-id="headerBar"
            justifyContent="center"
            sx={{ backgroundColor: 'grey.100', minHeight: (theme) => theme.spacing(10), ...sx }}
            container
        >
            {title && (
                <Container sx={sxs([titleContainerSx, titleContainerSxOverride])}>
                    {titleElement}
                </Container>
            )}
        </Grid>
    );
};

export default HeaderBar;
