import { FC, PropsWithChildren, useEffect } from 'react';
import { launchAmplitude } from '@config/amplitude';
import { handleAxeptioTrackingScriptsConsent, removeAxeptio } from '@config/axeptio';
import { launchHotjar } from '@config/hotjar';

interface TrackingWrapperProps extends PropsWithChildren {
    isLoggedUser: boolean;
}
const TrackingWrapper: FC<TrackingWrapperProps> = ({ children, isLoggedUser }) => {
    useEffect(() => {
        if (isLoggedUser) {
            // Force amplitude and Hotjar tracking for logged users, no need of axeptio consent
            launchAmplitude(isLoggedUser);
            launchHotjar();
            removeAxeptio();
        } else {
            // For non logged users, we need to wait for axeptio consent
            handleAxeptioTrackingScriptsConsent(isLoggedUser);
        }
    }, [isLoggedUser]);

    return children;
};

export default TrackingWrapper;
