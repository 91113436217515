import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getPerformance } from 'firebase/performance';
import { getStorage } from 'firebase/storage';
import { getEnvVar } from '../configEnv';

const clientCredentials = {
    apiKey: getEnvVar('FIREBASE_API_KEY'),
    appId: getEnvVar('FIREBASE_APP_ID'),
    authDomain: getEnvVar('FIREBASE_AUTH_DOMAIN'),
    databaseURL: getEnvVar('FIREBASE_DATABASE_URL'),
    projectId: getEnvVar('FIREBASE_PROJECT_ID'),
    storageBucket: getEnvVar('FIREBASE_STORAGE_BUCKET'),
};

const isClientSide = typeof window !== 'undefined';

// Initialize app
const firebaseApp = initializeApp(clientCredentials);

// Auth
const auth = getAuth(firebaseApp);

// Firestore
const db = getFirestore(firebaseApp);

// Storage
const storage = getStorage(firebaseApp);

// Functions
const functions = getFunctions(firebaseApp, 'europe-west1');

if (process.env.NEXT_PUBLIC_EMULATED_FUNCTIONS) {
    connectFunctionsEmulator(functions, 'localhost', 5556);
}

// Performance
const perf = isClientSide ? getPerformance(firebaseApp) : undefined;

export { auth, db, firebaseApp, functions, perf, storage };
