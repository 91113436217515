import { AlertProps } from '@mui/lab';
import { Alert as MuiAlert } from '@mui/material';
import { FC } from 'react';
import { sxs } from '@utils/mui/sx';

const Alert: FC<AlertProps> = ({ children, sx = {}, ...alertProps }) => (
    <MuiAlert sx={sxs([{ alignItems: 'center' }, sx])} {...alertProps}>
        {children}
    </MuiAlert>
);

export default Alert;
