/* eslint-disable no-underscore-dangle,no-void,no-unused-expressions */
import { launchHotjar } from './hotjar';
import { launchAmplitude } from '@config/amplitude';

const isBrowser = process.browser;

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _axcb: any;
        axeptioSettings: {
            clientId: string;
            cookiesVersion: string;
        };
    }
}

// from https://www.w3schools.com/js/js_cookies.asp
export const getCookieValue = (cookieName: string): string => {
    if (!isBrowser) {
        return '';
    }
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
        const cookie = cookiesArray[i].trim();

        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return '';
};

export const handleAxeptioTrackingScriptsConsent = (isLoggedUser: boolean) => {
    if (isBrowser) {
        void 0 === window._axcb && (window._axcb = []);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window._axcb.push((axeptio: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            axeptio.on('cookies:complete', async (choices: any) => {
                const hotjarElement = document.getElementById('script-hotjar');
                if (choices.amplitude) {
                    await launchAmplitude(isLoggedUser);
                }
                if (choices.hotjar && !hotjarElement) {
                    launchHotjar();
                } else if (!choices.hotjar && hotjarElement) {
                    hotjarElement.remove();
                }
            });
        });
    }
};

export const openDialog = (): void => {
    if (window._axcb) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window._axcb.push((axeptio: any) => {
            axeptio.openCookies();
        });
    }
};

export const removeAxeptio = (): void => {
    ['cookies-manager', 'axeptio_overlay'].forEach((id) => {
        const element = document.getElementById(id);
        element?.remove();
    });
};
