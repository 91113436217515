import { BannerNotificationWithId } from '@livv/models';
import { differenceInSeconds } from 'date-fns';
import { Query, Timestamp, query, where } from 'firebase/firestore';
import { useEffect, useMemo, useState, FC, useContext, createContext, ReactNode } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import getLocalBannerNotifications from '@utils/bannerNotifications';
import { bannerNotificationsCollection } from '@utils/collections';
import { NOTIFICATIONS_STORAGE_KEY } from '@utils/consts/bannerNotifications';
import useUser from '@utils/context/user';
import BaseStorage from '@utils/localStorage/BaseStorage';

type NotificationsDismissedOn = Date;
const now = new Date();

const notificationStorage = new BaseStorage<NotificationsDismissedOn>(NOTIFICATIONS_STORAGE_KEY, {
    format: JSON.stringify,
    parse: JSON.parse,
});

const isDismissed = ({
    id,
    notificationRespawnDelayInSeconds,
}: BannerNotificationWithId): boolean => {
    const dismissedOn = notificationStorage.getItem(id);
    if (!dismissedOn) {
        return false;
    }
    if (!notificationRespawnDelayInSeconds) {
        return true;
    }

    return differenceInSeconds(now, dismissedOn) < notificationRespawnDelayInSeconds;
};

interface BannerNotificationContext {
    handleDismissNotification: (notificationId: string) => void;
    loading: boolean;
    notifications?: BannerNotificationWithId[];
}

const nowTimestamp = Timestamp.now();

const useProvideBannerNotifications = (): BannerNotificationContext => {
    const { privileges } = useUser();
    const [firestoreNotifications, setFirestoreNotifications] = useState<
        BannerNotificationWithId[]
    >([]);
    const [localNotifications, setLocalNotifications] = useState<BannerNotificationWithId[]>([]);

    const [firestoreBannerNotificationsData, loading] = useCollectionData<BannerNotificationWithId>(
        query(
            bannerNotificationsCollection,
            where('endDate', '>=', nowTimestamp),
        ) as Query<BannerNotificationWithId>,
    );

    const validFirestoreBannerNotificationsData = useMemo(
        () =>
            firestoreBannerNotificationsData?.filter(
                (notification) =>
                    notification?.startDate &&
                    notification.startDate <= nowTimestamp &&
                    !isDismissed(notification),
            ) || [],
        [firestoreBannerNotificationsData],
    );

    const handleDismissNotification = (notificationId: string) => {
        setFirestoreNotifications(firestoreNotifications.filter(({ id }) => id !== notificationId));
        setLocalNotifications(localNotifications.filter(({ id }) => id !== notificationId));
        notificationStorage.setItem(notificationId, new Date());
    };

    useEffect(() => {
        setLocalNotifications(getLocalBannerNotifications(privileges));
    }, [privileges]);

    useEffect(() => {
        setFirestoreNotifications(validFirestoreBannerNotificationsData);
    }, [validFirestoreBannerNotificationsData]);

    return {
        handleDismissNotification,
        loading,
        notifications: [...localNotifications, ...firestoreNotifications],
    };
};

const bannerNotificationContext = createContext<BannerNotificationContext>({
    handleDismissNotification: () => {},
    loading: true,
    notifications: [],
});

const useBannerNotifications = (): BannerNotificationContext =>
    useContext(bannerNotificationContext);

export const BannerNotificationContextProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    const value = useProvideBannerNotifications();

    return (
        <bannerNotificationContext.Provider value={value}>
            {children}
        </bannerNotificationContext.Provider>
    );
};

export default useBannerNotifications;
