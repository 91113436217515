import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import { AlertProps } from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import { ReactElement, useState } from 'react';
import Alert from '@components/Alert';

interface AlertSnackBarProps extends AlertProps, Pick<SnackbarProps, 'anchorOrigin'> {
    duration?: number;
    onClose?: () => void;
    onOpen?: () => void;
}

const useAlertSnackBar = ({
    anchorOrigin,
    duration = 5000,
    onClose,
    onOpen,
    ...alertProps
}: AlertSnackBarProps): [ReactElement, (message: string) => void, () => void] => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');

    const handleOpen = () => {
        if (onOpen) onOpen();
        setIsOpen(true);
    };

    const handleClose = () => {
        if (onClose) onClose();
        setIsOpen(false);
    };

    const addMessage = (newMessage: string) => {
        setMessage(newMessage);
        handleOpen();
    };

    const snackBarElement = (
        <Snackbar
            anchorOrigin={anchorOrigin || { horizontal: 'center', vertical: 'bottom' }}
            autoHideDuration={duration}
            onClose={handleClose}
            open={isOpen}
        >
            {/*
            Don't remove these divs ! it will break
            https://mui.com/guides/migration-v4/#cannot-read-property-scrolltop-of-null */}
            <div>
                <Alert
                    elevation={6}
                    icon={
                        alertProps?.severity === 'success' ? (
                            <CheckCircleOutlineIcon sx={{ color: 'common.white' }} />
                        ) : null
                    }
                    onClose={handleClose}
                    {...alertProps}
                    variant={alertProps.variant || 'filled'}
                >
                    <Typography color="common.white" fontWeight={600} variant="body2">
                        {message}
                    </Typography>
                </Alert>
            </div>
        </Snackbar>
    );

    return [snackBarElement, addMessage, handleClose];
};

export default useAlertSnackBar;
