import { User } from '@livv/models';
import { UserPrivileges } from '@models/users';

export enum UserPropertiesName {
    IS_ADMIN = 'is_admin',
    IS_INTERNAL = 'is_internal',
    IS_PAID = 'is_paid',
    IS_TRIAL = 'is_trial',
    EXPIRED_TRIAL = 'expired_trial',
}

export type UserProperties = Record<UserPropertiesName, boolean>;

const INTERNAL_EMAIL_DOMAIN = 'vogel-vogel.com';

export const processUserProperties = (
    userInfo: User,
    userPrivileges: UserPrivileges,
): UserProperties => {
    const { email } = userInfo;
    const { isPaidCustomer, role, trialEndDate } = userPrivileges;

    return {
        [UserPropertiesName.IS_ADMIN]: role === 'admin',
        [UserPropertiesName.IS_PAID]: isPaidCustomer,
        [UserPropertiesName.IS_INTERNAL]: role === 'user' && email.endsWith(INTERNAL_EMAIL_DOMAIN),
        [UserPropertiesName.EXPIRED_TRIAL]: trialEndDate ? trialEndDate > Date.now() : false,
        [UserPropertiesName.IS_TRIAL]: !isPaidCustomer,
    };
};
