import { FC, createContext, useMemo, ReactNode, useContext, useState, ComponentType } from 'react';
import useUser from './user';
import { FreemiumDialogProps } from '@components/dialogs/FreemiumDialog';
import isAllowedToConsumePaidContent from '@utils/user';

interface FreemiumDialogContextProps {
    openFreemiumDialog?: () => void;
}

const FreemiumDialogContext = createContext<FreemiumDialogContextProps | null>(null);

export const useFreemiumDialog = (): FreemiumDialogContextProps => {
    const value = useContext(FreemiumDialogContext);
    if (!value) throw new Error('FreemiumDialog context needed');

    return value;
};

const FreemiumDialogContextProvider: FC<{
    children: ReactNode;
    FreemiumDialog: ComponentType<FreemiumDialogProps>;
}> = ({ children, FreemiumDialog }) => {
    const [open, setOpen] = useState(false);

    const { privileges } = useUser();
    const isPremiumUser = isAllowedToConsumePaidContent(privileges);

    const contextValue = useMemo<FreemiumDialogContextProps>(
        () => ({ openFreemiumDialog: isPremiumUser ? undefined : () => setOpen(true) }),
        [isPremiumUser],
    );

    return (
        <FreemiumDialogContext.Provider value={contextValue}>
            {children}
            {!isPremiumUser && <FreemiumDialog onClose={() => setOpen(false)} open={open} />}
        </FreemiumDialogContext.Provider>
    );
};

export default FreemiumDialogContextProvider;
