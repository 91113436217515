import { FirebaseError } from 'firebase/app';
import {
    ActionCodeSettings,
    EmailAuthProvider,
    User as FirebaseUser,
    createUserWithEmailAndPassword,
    confirmPasswordReset as firebaseConfirmPasswordReset,
    onIdTokenChanged,
    reauthenticateWithCredential,
    signInWithEmailAndPassword,
    updatePassword,
    updateProfile,
} from 'firebase/auth';
import { UserRecord } from 'firebase-admin/lib/auth/user-record';
import { useRouter } from 'next/router';
import nookies from 'nookies';
import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { auth as firebaseAuth } from '@config/firebase/client';
import { userAuth } from '@functions';
import { AuthEvent } from '@utils/consts/events';

interface BaseAuthProps {
    emailVerified: boolean;
    uid: string;
}

export type SigninType = (email: string, password: string) => Promise<FirebaseUser | null>;

export type AuthContextType = {
    changePassword: (newPassword: string) => Promise<boolean>;
    confirmPasswordReset: (password: string, resetCode: string) => Promise<boolean>;
    deleteUser: () => Promise<void>;
    errors: string | null;
    forceRefreshToken: () => Promise<string | undefined>;
    isLoading: boolean;
    logout: (redirect?: boolean) => Promise<void>;
    reauthenticateCurrentUser: (password: string) => Promise<boolean>;
    resetErrors: () => void;
    sendEmailVerification: (actionCodeSettings: ActionCodeSettings) => Promise<boolean>;
    sendPasswordResetEmail: (
        email: string,
        actionCodeSettings: ActionCodeSettings,
    ) => Promise<boolean>;
    signin: SigninType;
    signup: SigninType;
    updateDisplayName: (fullName: string) => void;
    user: BaseAuthProps | null;
};

export const AuthContext = createContext<AuthContextType>({
    changePassword: async () => false,
    confirmPasswordReset: async () => false,
    deleteUser: async () => {},
    errors: null,
    forceRefreshToken: async () => undefined,
    isLoading: true,
    logout: async () => {},
    reauthenticateCurrentUser: async () => false,
    resetErrors: () => {},
    sendEmailVerification: async () => false,
    sendPasswordResetEmail: async () => false,
    signin: async () => null,
    signup: async () => null,
    updateDisplayName: () => {},
    user: null,
});

export const useAuth = (): AuthContextType => useContext(AuthContext);

const useProvideAuth = (initialAuth?: BaseAuthProps | null): AuthContextType => {
    const [auth, setAuth] = useState<BaseAuthProps | null>(initialAuth ?? null);
    const [isLoading, setIsLoading] = useState(!initialAuth); // TODO: If not initialAuth, it is stuck in loading state
    const [errors, setErrors] = useState<string | null>(null);
    const router = useRouter();

    const resetErrors = useCallback(() => setErrors(null), []);

    const deleteUser = async () => {
        await firebaseAuth.currentUser?.delete();
    };

    const signin: SigninType = async (email, password) => {
        setIsLoading(true);
        try {
            resetErrors();
            const { user } = await signInWithEmailAndPassword(firebaseAuth, email, password);
            setAuth(user);

            return user;
        } catch (e) {
            if (e instanceof FirebaseError) {
                setErrors(e.code);
            }

            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const signup: SigninType = async (email, password) => {
        setIsLoading(true);
        try {
            resetErrors();
            const { user } = await createUserWithEmailAndPassword(firebaseAuth, email, password);
            setAuth(user);

            return user;
        } catch (e) {
            if (e instanceof FirebaseError) {
                setErrors(e.code);
            }

            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const updateDisplayName = async (fullName: string) => {
        try {
            if (firebaseAuth.currentUser) {
                await updateProfile(firebaseAuth.currentUser, { displayName: fullName });
            }
        } catch (e) {
            if (e instanceof FirebaseError) {
                setErrors(e.code);
            }
        }
    };

    const logout = async (redirect = true) => {
        setIsLoading(true);
        try {
            resetErrors();
            if (redirect) {
                await router.push('/signin');
            }
            await firebaseAuth.signOut();
            // Emit logout event specially for the BookPage to refresh the page (SSR)
            router.events.emit('routeChangeComplete', AuthEvent.LOGOUT);
            setAuth(null);
        } catch (e) {
            if (e instanceof FirebaseError) {
                setErrors(e.code);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const sendPasswordResetEmail = async (
        email: string,
        actionCodeSettings: ActionCodeSettings,
    ) => {
        try {
            resetErrors();
            await userAuth({ action: 'sendPasswordResetLink', actionCodeSettings, email });

            return true;
        } catch (e) {
            if (e instanceof FirebaseError) {
                setErrors(e.code);
            }

            return false;
        }
    };

    const confirmPasswordReset = async (password: string, resetCode: string) => {
        try {
            await firebaseConfirmPasswordReset(firebaseAuth, resetCode, password);

            return true;
        } catch (e) {
            if (e instanceof FirebaseError) {
                setErrors(e.code);
            }

            return false;
        }
    };

    const reauthenticateCurrentUser = async (password: string) => {
        try {
            const credentials = EmailAuthProvider.credential(
                firebaseAuth?.currentUser?.email || '',
                password,
            );
            const { user } = firebaseAuth?.currentUser
                ? await reauthenticateWithCredential(firebaseAuth?.currentUser, credentials)
                : { user: null };
            setAuth(user);

            return true;
        } catch (e) {
            if (e instanceof FirebaseError) {
                setErrors(e.code);
            }

            return false;
        }
    };

    const sendEmailVerification = async (
        actionCodeSettings: ActionCodeSettings,
    ): Promise<boolean> => {
        try {
            const email = firebaseAuth.currentUser?.email || '';
            await userAuth({ action: 'sendEmailValidationLink', actionCodeSettings, email });

            return true;
        } catch (e) {
            if (e instanceof FirebaseError) {
                setErrors(e.code);
            }

            return false;
        }
    };

    const changePassword = async (newPassword: string) => {
        try {
            if (firebaseAuth.currentUser) {
                await updatePassword(firebaseAuth.currentUser, newPassword);
            }

            return true;
        } catch (e) {
            if (e instanceof FirebaseError) {
                setErrors(e.code);
            }

            return false;
        }
    };

    const forceRefreshToken = async () => firebaseAuth.currentUser?.getIdToken(true);

    useEffect(() => {
        const unsubscribe = onIdTokenChanged(firebaseAuth, async (user) => {
            const token = (await user?.getIdToken()) ?? '';
            nookies.set(undefined, 'token', token, { path: '/' });

            if (Boolean(user) !== Boolean(auth)) {
                setAuth(user);
            }
            if (isLoading) {
                setIsLoading(false);
            }
        });

        const handleRouteChange = () => {
            resetErrors();
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            unsubscribe();
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [auth, isLoading, resetErrors, router.events]);

    return {
        changePassword,
        confirmPasswordReset,
        deleteUser,
        errors,
        forceRefreshToken,
        isLoading,
        logout,
        reauthenticateCurrentUser,
        resetErrors,
        sendEmailVerification,
        sendPasswordResetEmail,
        signin,
        signup,
        updateDisplayName,
        user: auth,
    };
};

export const AuthProvider: FC<{
    children: ReactNode;
    initialAuth?: UserRecord | null;
}> = ({ children, initialAuth }) => {
    const auth = useProvideAuth(initialAuth);

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
