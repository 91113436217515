import { Box } from '@mui/material';
import { FirebaseError } from 'firebase-admin';
import router from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import BaseError from '@components/BaseError';

// We need to resetErrorBoundary before any action on this page.
// Otherwise routing doesn't work.

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
    const { t } = useTranslation('common');

    if ((error as unknown as FirebaseError).code === 'permission-denied') {
        resetErrorBoundary();
        router.push(`/signin?redirectUrl=${encodeURIComponent(router.asPath)}`);

        return null;
    }

    return (
        <Box data-test-id={error.name} sx={{ pt: '10vh' }}>
            <BaseError
                linkHref="/"
                linkLabel={t('error.fallback.link')}
                logo="/assets/error.svg"
                subTitle={t('error.fallback.subtitle')}
                title={t('error.fallback.title')}
                refreshOnNavigation
            />
        </Box>
    );
};

export default ErrorFallback;
